.hero {
    position: relative;
    background-image: url('../../../assets/imgs/lord-ganesh.jpeg'); /* Replace 'your-image.jpg' with the path to your image */
    background-size: cover;
    background-position: center;
    height: 100vh;
    text-align: center;
    color: #faed04; /* Text color */
}

/* Purple shade overlay */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #31324ebe; /* Purple shade with opacity */
}

/* Centered text */
.hero-text h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Glass Antiqua', cursive;
    font-weight: 800;
    font-size: 4rem; /* Adjust font size as needed */
}
.hero-text p {
    color: #fff;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Glass Antiqua', cursive;
    font-weight: 800;
    font-size: 1.6rem; /* Adjust font size as needed */
}
/* For screens with a width of 1200px or more */
@media (max-width: 1200px) {
    .hero-text h1 {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5rem; /* Adjust font size for larger screens */
    }
    .hero-text p {
        font-size: 2rem; /* Adjust font size for larger screens */
    }
}

/* For screens with a width between 720px and 1199px */
@media (max-width: 720px)  {
    .hero-text h1 {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3.5rem; /* Adjust font size for medium-sized screens */
    }
    .hero-text p {
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.2rem; /* Adjust font size for medium-sized screens */
    }
}

.socials{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0;
}
