.contactcontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin:  auto;
    width: 100%;
    max-width: 1280px;
    padding: 200px 15px 20px;
}

.contactcontent {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 50px auto;
    width: 90%;
}

.contactcontent h1 {
    color: #eca816;
    font-size: 36px;
    margin-bottom: 20px;
}

.contactcontent p {
    color: #333;
    font-size: 18px;
    margin-bottom: 30px;
}

.contactcontent .contact-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    /* flex-wrap: wrap; */
    
}

.contactcontent .contact-link {
    text-decoration: none;
    background-color: #eca816;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-size: 14px;
    display: flex;
    
    align-items: center;
}

.contact-link:hover {
    background-color: #b88008;
}

.contact-link i {
    margin-right: 10px;
}
@media (max-width:720px){
    .contactcontent .contact-links {
       
        flex-direction: column;
        align-items: center;
        /* flex-wrap: wrap; */
        
    }
    .contactcontent .contact-link {
        width: 100%;
        text-align: center;
        justify-content: center;
        
    }

    
}