body{
    margin:0;
    overflow-x:hidden;
    }
    
    .footer{
    /* background:#000; */
    padding:30px 0px;
    font-family: 'Play', sans-serif;
    text-align:center;
    }
    
    .footer .footer-row{
    width:100%;
    margin:1% 0%;
    padding:0.6% 0%;
    color:rgb(79, 78, 78);
    font-size:0.8em;
    }
    
    .footer .footer-row a{
    text-decoration:none;
    color:rgb(79, 78, 78);
    transition:0.5s;
    }
    
    .footer .footer-row a:hover{
    color:#000;
    }
    
    .footer .footer-row ul{
    width:100%;
    }
    
    .footer .footer-row ul li{
    display:inline-block;
    margin:0px 30px;
    }
    
    .footer .footer-row a i{
    font-size:2em;
    margin:0% 1%;
    }
    
    @media (max-width:720px){
    .footer{
    text-align:left;
    padding:5%;
    }
    .footer .footer-row ul li{
    display:block;
    margin:10px 0px;
    text-align:left;
    }
    .footer .footer-row a i{
    margin:0% 3%;
    }
    }