.careercontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin:  auto;
    width: 100%;
    max-width: 1200px;
    padding: 200px 15px 20px;
}

.careercontent 
     {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        text-align: center;
    }

    .careercontent h1 {
        color: #eca816;
        /* color: #333; */
    }

    .careercontent p {
        color: #666;
        margin-top: 10px;
    }

    .careercontent .button {
        display: inline-block;
        padding: 10px 20px;
        background-color:   #eca816;;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;
        transition: background-color 0.3s ease;
        cursor: pointer;
    }
    .careercontent a{
        text-decoration: none;
        color: white;
    }

    .careercontent .button:hover {
        background-color:  #a97504;;
    }