.bestseller-container {
    text-align: center;
    padding: 40px 0;
    background-color: #eca816; /* Set your desired background color */
  }

  /* CSS for the bestseller component */
.bestseller-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px 0;
    
  }
  
  .bestsell-row {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    /* width: 100%; */
    max-width: 1220px;
    }
  
  .bestsell-col {
   
    margin: 10px;
    background-color: #fff; /* Set card background color */
    /* padding: 5px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    transition: transform 0.2s; /* Add a smooth hover effect */
    text-align: center;
    border-radius: 4px;
    width: 300px;
  }
  
  .bestsell-col img {
    max-width: 100%;
    height: auto;
    /* border: 1px solid #ddd; Add a border to the image */
    border-radius: 4px; /* Add rounded corners to the image */
  }
  
  .bestsell-text-wrapper {
    /* padding: 20px; */
    text-align: center;
  }
  
  .bestsell-text-wrapper p {
    font-size: 18px;
    color: #333; /* Set your desired text color */
  }
  
  .bestsell-text-wrapper p span {
    font-size: 20px;
    font-weight: bold;
  }
  
  .bestsell-text-wrapper h2 {
    font-size: 24px;
    margin: 10px 0;
    color: #333; /* Set your desired text color */
  }
  
  .bestsell-text-wrapper a {
    background-color: #007bff; /* Set your desired button background color */
    color: #fff; /* Set your desired button text color */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 10px 5px;
    text-decoration: none;
    transition: background-color 0.3s; /* Add a smooth hover effect */
  }
  
  .bestsell-text-wrapper a:hover {
    background-color: #0056b3; /* Change button color on hover */
  }
  @media (max-width: 768px) {
    .bestsell-col {
      width: 140px;
    }
    .bestsell-text-wrapper h2{
      font-size: 12px;
    }
    .bestsell-text-wrapper a {
      padding: 2px 5px;
      font-size: 12px;
      cursor: pointer;
      margin: 10px 5px;
    }
    
  }