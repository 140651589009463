.aboutuscontainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:  auto;
    width: 100%;
    max-width: 1200px;
    padding: 200px 15px 20px;
}
.aboutuscontent{
    width: 80%;
    text-align: justify;
}
.aboutcontentpara{
    padding-left: 30px;
    padding-top:10px ;
}
.aboutuscontent p{
padding-bottom: 20px;
}
