/* FeatureProduct.css */

/* Container for the feature product section */
.featureproduct-container {
    text-align: center;
    padding: 40px 0;
    background-color: #f7f7f7; /* Set your desired background color */
  }
  
  /* Title style */
  .featureproduct-container h1 {
    font-size: clamp(2rem,4vw + .8vmin,.9em);
    font-weight: bold;
    margin-bottom: 1rem;
    letter-spacing: .1rem;
    margin-bottom: 30px;
    
  }
  
  /* Wrapper for the individual feature product cards */
  .featureproduct-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

 
  