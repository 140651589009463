
.singleproduct-container{
  display: flex;
flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:  auto;
  width: 100%;
  max-width: 1200px;
  padding: 200px 15px 20px;
 
}

/* Styles for the product list */
.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .product{
    -webkit-box-shadow: 5px 6px 14px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 6px 14px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 6px 14px 0px rgba(0,0,0,0.75);
    text-align: center;
    padding: 1rem;
    background-color: blanchedalmond;
  }
  
  .product-item {
    text-align: justify;
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    grid-gap: 20px;
    /* width: 45%; */
    margin-bottom: 20px;
    padding: 10px;
    /* border: 1px solid #ccc; */
    
  }
  .product-image{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 300px;
  }
  .product-image img {
    width: 100%;
    height: auto;
  }
  
  .product-thumbnails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
  
  /* Styles for the image previews */
  .image-preview {
    width: 60px;
    height: 60px;
    margin: 5px;

    cursor: pointer;
  }
  
  .image-preview img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
  }
  
  /* Additional styles for product information */
  .productdetails{
    margin: auto;
    padding: 1rem;
  }
  h3 {
    font-weight: 800;
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  p {
    font-size: 1rem;
    margin:2% ;
    font-weight: 600;
    margin: 5px 0;
  }
  
  .productLink{
    display: flex;
    justify-content: space-around;
    align-items: center;

  }
 .productdetails a {
    text-decoration: none;
    background-color: #007bff; /* Set your desired button background color */
    color: #fff; /* Set your desired button text color */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s; /* Add a smooth hover effect */
  }
  
  .productdetails a:hover {
    background-color: #0056b3; /* Change button color on hover */
  }

  .similarProduct{
    margin-top: 3rem;
  }
  .productpayment{
    margin-top:1rem ;
    color: #3c3b3b;
    
  }
  .productpayment p{
    font-size: 12px;
    font-weight: 400;
  }


  /* Responsive layout */
  @media (max-width: 768px) {
    
    .product-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      grid-gap: 20px;
      width: 100%;
    }
    .product{
      width: 100%;
    }

    .product-thumbnails {
      flex-direction: row;
      justify-content: center;
    }

    h3 {
      font-weight: 800;
      font-size: 1.2rem;
      margin: 10px 0;
    }
    
    p {
      font-size: 1rem;
      margin:2% ;
      font-weight: 600;
      margin: 5px 0;
    }
  }
  