.knowus-container{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 50px 0;
    /* background-color: #e3e0d6; */
    width: 70%;
}
.knowus-container h1 {
    font-size: clamp(2rem,4vw + .8vmin,.9em);
    font-weight: bold;
    margin-bottom: 1rem;
    /* color: #faed04; */
    letter-spacing: .1rem;

}
