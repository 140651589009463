
  /* Style for each feature product card */
  .featureproduct-card {
    width: 300px;
    margin: 20px;
    padding: 10px;
    background-color: #fff; /* Set card background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    transition: transform 0.2s; /* Add a smooth hover effect */
  }
  /* a{
    text-decoration: none;
    width: 100%;
  } */
  
  .featureproduct-card:hover {
    transform: scale(1.05); /* Scale up the card on hover */
  }
  
  /* Image style */
  .featureproduct-img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd; /* Add a border to the image */
    border-radius: 4px; /* Add rounded corners to the image */
  }
  
  /* Text inside the card */
  .featureproduct-text {
    padding: 20px;
    text-align: left;
  }
  
  /* Title of the feature product */
  .featureproduct-text h2 {
    font-size: 20px;
    margin: 0;
    color: #333; /* Set your desired text color */
  }
  
  /* Price text style */
  .featureproduct-text p {
    font-size: 16px;
    margin: 10px 0;
    color: #7c7c7c; /* Set your desired text color */
  }
  
  /* Add to Cart button style */
  .featureproduct-text a {
    text-decoration: none;
    background-color: #007bff; /* Set your desired button background color */
    color: #fff; /* Set your desired button text color */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s; /* Add a smooth hover effect */
  }
  
  .featureproduct-text a:hover {
    background-color: #0056b3; /* Change button color on hover */
  }

  @media (max-width: 720px)  {
    .featureproduct-card {
      margin: 5px ;
      width: 150px;
    } 
    .featureproduct-text {
      padding: 5px;
      text-align: left;
    }

    .featureproduct-text h2 {
      font-size: 10px;
      margin: 0;
      color: #333; /* Set your desired text color */
    }

    .featureproduct-text p {
      font-size: 9px;
      margin: 5px 0;
      color: #7c7c7c; /* Set your desired text color */
    }

    .featureproduct-text a {
      text-decoration: none;
      background-color: #007bff; /* Set your desired button background color */
      color: #fff; /* Set your desired button text color */
      border: none;
      padding: 10px 5px;
      font-size: 10px;
      cursor: pointer;
      transition: background-color 0.3s; /* Add a smooth hover effect */
    }
  }



  .sale {
    position: absolute;
    display: inline-block;
    background: orange;
    color: white;
    font-weight: 800;
    height: 2.5rem;
    width: 2.5rem;
    /* text-align: center; */
    /* vertical-align: middle; */
    line-height: 2.5rem;
    /* margin: 45vh 45vw; */
    transform: rotate(-20deg);
    animation: beat 1s ease infinite alternate;
    &:before,
    &:after {
      content:"";
      position: absolute;
      background: inherit;
      height: inherit;
      width: inherit;
      top: 0;
      left: 0;
      z-index: -1;
      transform: rotate(30deg);
    }
    &:after {
      transform: rotate(60deg);
    }
  }
  
  @keyframes beat {
    from {	transform: rotate(-20deg) scale(1); }
    to {	transform: rotate(-20deg) scale(1.1); }
  }