.termcontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin:  auto;
    width: 100%;
    max-width: 1200px;
    padding: 200px 15px 20px;
}
.termcontent{
    width: 80%;
    text-align: justify;
}
.termcontainer h1{
    text-align: center;
    color: #eca816;
}
.termcontent p ul li{
    list-style: none;
    margin: 10px 0;
}